import React from 'react';
import { createRoot } from 'react-dom/client';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './images/favicon.ico';

import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
