import React, { useRef, useState, useEffect } from 'react';
import AppNavbar from './AppNavbar';
import Footer from './components/Footer';
import JitterText from './components/JitterText';
import ThemeToggle from './components/ThemeToggle';
import pusheenVideo from './videos/pusheen_tippy_taps.webm';
import './App.css';

function App() {
  const [isVideoSpedUp, setIsVideoSpedUp] = useState(false);
  const [isDark, setIsDark] = useState(() => {
    // Check localStorage first, then system preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const videoRef = useRef(null);

  // Apply theme on mount and when it changes
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
  }, [isDark]);

  // Listen for system theme changes only if no preference is saved
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      // Only update if there's no saved preference
      if (!localStorage.getItem('theme')) {
        setIsDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
      setIsVideoSpedUp(true);
      setTimeout(() => {
        videoRef.current.playbackRate = 1;
        setIsVideoSpedUp(false);
      }, 2000);
    }
  };

  const handleThemeToggle = () => {
    setIsDark((prev) => !prev);
  };

  return (
    <div className="App">
      <ThemeToggle isDark={isDark} onToggle={handleThemeToggle} />
      <AppNavbar />
      <header className="App-header">
        <video
          ref={videoRef}
          className={`unselectable ${isVideoSpedUp ? 'sped-up' : ''}`}
          autoPlay
          loop
          muted
          playsInline
          onClick={handleVideoClick}
          src={pusheenVideo}
        />
        <JitterText text="Meow Meow Meow" isActive={isVideoSpedUp} />
      </header>
      <Footer />
    </div>
  );
}

export default App;
