import React from 'react';
import './JitterText.css';

function JitterText({ text, isActive }) {
  return (
    <div className="jitter-text">
      {text.split('').map((char, index) => (
        <span
          key={index}
          className={`jitter-letter ${isActive ? 'active' : ''}`}
          style={{
            animationDelay: `${index * 0.05}s`
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
}

export default JitterText; 
