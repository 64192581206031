import React from 'react';
import { Navbar } from 'react-bootstrap';

class AppNavbar extends React.Component {
  render() {
    return (
      <Navbar className="py-3">
        <Navbar.Brand href="/"></Navbar.Brand>
      </Navbar>
    );
  }
}

export default AppNavbar;
